/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from "react";
import { RiMicLine } from "react-icons/ri";
import ".././styles.css";
import { demoURL } from "../../client.js";
import Modal from "../modal.js";
import { ToastContainer, toast } from "react-toastify";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useSpring, animated } from "@react-spring/web";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Pulsating from "../pulsation.js";
import Sidebar from "../sidebar.js";
import { useNavigate } from "react-router-dom";
import Loading from "../loading.js";
import axios from "axios";
import { GlobalStateContext } from "../../Context/GlobalStateContext.js";
import { useDispatch } from "react-redux";
import { logout } from "../../features/auth/authSlice.js";

const StyledDiv = styled.div`
  align-items: center;
  background: ${({ color }) => color || "#AA0000"};
  border-radius: 32px;
  color: white;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 130px;
  padding: 5px;
`;

function Number({ n }) {
  const { number } = useSpring({
    from: { number: n },
    number: 0,
    delay: 1000,
    config: { mass: 1, tension: 50 },
  });

  return (
    <animated.div className="text-9xl font-extrabold text-blue-800 flex p-20">
      {number.to((n) => n.toFixed(0))}
    </animated.div>
  );
}

const Transcribing = () => {
 
  const {user,setUser,checker,setOriginal,setIcdCodes,setSubjective, setObjective, setmed,setCptCodes, setDxCodes, setAssessment, setPlan, setSoapNotesSummary, setAllergy, setHPI, setPMH, setROS, setchiefComplaint,setphysicalExamination} = useContext(GlobalStateContext)
  

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { patientId } = useParams();
  const [affirmation,setAffirmation]=useState("")
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(true);
  const [showStartButton, setShowStartButton] = useState(true);
  const [showColor, setShowColor] = useState(false);
  const [iscounting, setIscounting] = useState(false);
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const [stop, setStop] = useState(false);
  const textareaRef = useRef(null);
  const [lan,setLang] = useState("en")
  const dispatch = useDispatch()
  const [model,setModel]=useState("nova-medical")
  const [btn,setBtn]=useState(true)  
  
  let currentText = '';
  var audioText;
  let socket;
  useEffect(()=>{
    localStorage.removeItem("visit-id")
    checker().then((res)=>{
      if(res==false)
      {
      setUser(false)
      dispatch(logout())
      navigate('/')
      }
    })
    
  },[checker,user])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [affirmation]);





  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState != 'visible') 
      {
        if (mediaRecorder) 
          {
          setIsRecording(false);
          mediaRecorder.pause();
          } 
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  const activateMicrophone=async()=>{
    try {
      if(lan != "en")
      {
        setModel("nova")
      }
      setStop(false);
      setShowStartButton(false);
      setIscounting(false);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      if (!MediaRecorder.isTypeSupported('audio/webm')) {
        toast.error('Browser not supported');
        
        return;
      }

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm',
      });

      setMediaRecorder(mediaRecorder);

      
      
      socket = new WebSocket(`wss://api.deepgram.com/v1/listen?Language=${lan}&Model=${model}`, [
        'token',
        "da82ab87ef7c3ad6e8250b9774cadc66870ec126",
      ]);
      socketRef.current = socket;

      socket.onopen = () => {
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
          }
        });
        mediaRecorder.start(1000);
      };

      socket.onmessage = async (message) => {
        const received = JSON.parse(message.data);
        const transcript = received.channel.alternatives[0].transcript;
        if (transcript && received.is_final) {
          currentText = currentText.concat(' ' + transcript);
          audioText = currentText;
          setAffirmation(audioText);

        }
      };

      socket.onerror = (error) => {
        console.log(error)
      };

      socket.onclose = () => {
        stream.getTracks().forEach((track) => {
                  if (track.readyState === "live" && track.kind === "audio") {
                    track.stop();
                  }
                });
      };

    } catch (error) {
      console.error('Error');
    }

  }

  const pause = () => {
    if (mediaRecorder) {
      setIsRecording(false);
      mediaRecorder.pause();
    }
  };


  const handleInputChange = (event) => {
      setAffirmation(event.target.value);
  };




  // const sendMessageToBackend = async () => {
  //   try {
  //     setDxCodes([])
  //     setAllergy("")
  //     setAssessment("")
  //     setCptCodes([])
  //     setIcdCodes([])
  //     setHPI("")
  //     setPMH("")
  //     setPlan("")
  //     setphysicalExamination("")
  //     setObjective("")
  //     setSubjective("")
  //     setmed("")
  //     setROS("")
  //     // setRationale({})
  //     setchiefComplaint("")
  //     setSoapNotesSummary("")
  //     setOriginal("")
  //     if(affirmation.length=="")
  //     {
  //       toast.error("No conversation")
  //       setLoading(false)
  //       return
  //     }
  //     const formData = new FormData();
  //     formData.append('text', affirmation);
  //     formData.append('type', 'text');
  //     formData.append('practice', 'main-aims')
  //     const response = await axios.post(`${demoURL}/post/generateReportFromAudioFile`,formData);
  //     console.log(response)
  //       if(response.status === 200){
    
  //         const codes = response.data.code
  //         const data = response.data.data
  //         const listofros = response.data.Ros
  //         // const rationale = response.data.Rationale

  //         setOriginal(affirmation)
  //         localStorage.setItem("previous",affirmation)

  //         if(data.Medications==null || data.Medications=="" )
  //         {
  //           setmed("Not discussed during the consultation.")
  //         }else{
  //           setmed(data.Medications)
  //         }
  //         // ------------------------------------
  //         if(data.Assessment == null || data.Assessment=="")
  //         {
  //           setAssessment("Not discussed during the consultation.");
  //         }else{
  //           setAssessment(data.Assessment);
  //         }
  //         // ---------------------------------------
  //         if(codes['ICD-10 Codes'] == null || codes['ICD-10 Codes']  == "" || codes['ICD-10 Codes'] == [] ){
  //           setIcdCodes([{code:"null",description:"Not discussed during the consultation."}])
  //         }else{
  //           setIcdCodes(codes['ICD-10 Codes'])
  //         }
  //         if(codes['CPT Codes'] == null || codes['CPT Codes'] == "" || codes['CPT Codes']== []){
  //           setCptCodes([{code:"null",description:"Not discussed during the consultation."}])
  //         }else{
  //           setCptCodes(codes['CPT Codes'])
  //         }
  //         if(data.Plan==null || data.Plan=="")
  //         {
  //           setPlan("Not discussed during the consultation.");
  //         }else{
  //           setPlan(data.Plan);
  //         }
  
  //         if(data.SUMMARY==null || data.SUMMARY=="")
  //         {
  //           setSoapNotesSummary("Not discussed during the consultation.");
  //         }else{
  //           setSoapNotesSummary(data.SUMMARY);
  //         }
  
  //         if(data.Allergies==null || data.Allergies=="")
  //         {
  //           setAllergy("Not discussed during the consultation.");
  //         }else{
  //           setAllergy(data.Allergies);
  //         }
  
  //         if(data['History of Present Illness (HPI)']==null || data['History of Present Illness (HPI)']=="")
  //         {
  //           setHPI("Not discussed during the consultation.");
  //         }
  //         else{
  //           setHPI(data['History of Present Illness (HPI)']);
  //         }
  
  //         if(data['Past Medical History (PMH)']==null || data['Past Medical History (PMH)']=="")
  //         {
  //           setPMH("Not discussed during the consultation.");
  //         }else{
  //           setPMH(data['Past Medical History (PMH)']);
  //         }

  //         // Ratioale 
  //         //  const rationaleObj = {
  //         //   "post_concussion_evaluation": rationale.post_concussion_evaluation==""?"":rationale.post_concussion_evaluation,
  //         //   "dti_brain_mri": rationale.dti_brain_mri==""?"":rationale.dti_brain_mri,
  //         //   "iv_micronutrients_im_vitamins": rationale.iv_micronutrients_im_vitamins==""?"":rationale.iv_micronutrients_im_vitamins,
  //         //   "neurofeedback_clarity_direct": rationale.neurofeedback_clarity_direct==""?"":rationale.neurofeedback_clarity_direct
  //         // }
          
  //         //ros
  //         const rosData = {
  //           Constitutional: `${listofros.Constitutional['type']}. ${listofros.Constitutional['description']}` || 'Negative',
  //           Eyes: `${listofros.Eyes['type']}. ${listofros.Eyes['description']}` || 'Negative',
  //           ENT: `${listofros.ENT['type']}. ${listofros.ENT['description']}` || 'Negative',
  //           Cardiovascular:`${listofros.Cardiovascular['type']}. ${listofros.Cardiovascular['description']}` || "Negative",
  //           Respiratory: `${listofros.Respiratory['type']}. ${listofros.Respiratory['description']}` || 'Negative',
  //           Gastrointestinal: `${listofros.Gastrointestinal['type']}. ${listofros.Gastrointestinal['description']}` || 'Negative',
  //           Genitourinary: `${listofros.Genitourinary['type']}. ${listofros.Genitourinary['description']}` || 'Negative',
  //           Musculoskeletal: `${listofros.Musculoskeletal['type']}. ${listofros.Musculoskeletal['description']}` || 'Negative',
  //           Skin: `${listofros.Skin['type']}. ${listofros.Skin['description']}` || 'Negative',
  //           Neurological: `${listofros.Neurological['type']}. ${listofros.Neurological['description']}` || 'Negative',
  //           Psychiatric: `${listofros.Psychiatric['type']}. ${listofros.Psychiatric['description']}` || 'Negative',
  //         };
  
  //         // setROS(formatSymptoms(rosData));
  //         setROS(rosData)
  //         // setRationale(rationaleObj)
  
  //         if(data['Chief Complaint']==null || data['Chief Complaint']=="")
  //         {
  //           setchiefComplaint("Not discussed during the consultation.");
  //         }else{
  //           setchiefComplaint(data["Chief Complaint"]);
  //         }
  
  //         if(data['Physical Examination']==null || data['Physical Examination']=="")
  //         {
  //           setphysicalExamination("Not discussed during the consultation.");
  //         }
  //         else{          
  //           setphysicalExamination(data["Physical Examination"]);
  //         }
  
  //         if(data['Subjective']==null || data['Subjective']=="")
  //         {
  //           setSubjective("Not discussed during the consultation.");
  //         }else{
  
  //           setSubjective(data["Subjective"])
  //         }
  
  //         if(data['Objective']==null || data['Objective']=="")
  //         {
  //           setObjective("Not discussed during the consultation.");
  //         }else{
  //           setObjective(data["Objective"])
  //         }
  //         setLoading(false);
  //         navigate(`/SOAPnotes/${patientId}?mode=generate`);
  //       } 
      
     
  //     } catch (error) {
  //     setLoading(false);
  //   }finally{
  //     setLoading(false)
  //   }
  // } 

  const sendMessageToBackend = async () => {
    try {
      setDxCodes([]);
      setAllergy("");
      setAssessment("");
      setCptCodes([]);
      setIcdCodes([]);
      setHPI("");
      setPMH("");
      setPlan("");
      setphysicalExamination("");
      setObjective("");
      setSubjective("");
      setmed("");
      setROS("");
      setchiefComplaint("");
      setSoapNotesSummary("");
      setOriginal("");
  
      if (affirmation.length === 0) {
        toast.error("No conversation");
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('text', affirmation);
      formData.append('type', 'text');
      formData.append('practice', 'main-aims');
      
      const response = await axios.post(`${demoURL}/post/generateReportFromAudioFile`, formData);
      console.log(response)
      if (response.status === 200) {
        const codes = response.data.code;
        const data = response.data.data;
        const listofros = response.data.Ros;
        
        setOriginal(affirmation);
        localStorage.setItem("previous", affirmation);
  
        setmed(data.Medications || "Not discussed during the consultation.");
        setAssessment(data.Assessment || "Not discussed during the consultation.");
        setIcdCodes(codes['ICD-10 Codes'] && codes['ICD-10 Codes'].length > 0 ? codes['ICD-10 Codes'] : [{ code: "null", description: "Not discussed during the consultation." }]);
        setCptCodes(codes['CPT Codes'] && codes['CPT Codes'].length > 0 ? codes['CPT Codes'] : [{ code: "null", description: "Not discussed during the consultation." }]);
        setPlan(data.Plan || "Not discussed during the consultation.");
        setSoapNotesSummary(data.SUMMARY || "Not discussed during the consultation.");
        setAllergy(data.Allergies || "Not discussed during the consultation.");
        setHPI(data['History of Present Illness (HPI)'] || "Not discussed during the consultation.");
        setPMH(data['Past Medical History (PMH)'] || "Not discussed during the consultation.");
        
        const rosData = {
          Constitutional: `${listofros?.Constitutional?.type || 'Negative'}. ${listofros?.Constitutional?.description || ''}`,
          Eyes: `${listofros?.Eyes?.type || 'Negative'}. ${listofros?.Eyes?.description || ''}`,
          ENT: `${listofros?.ENT?.type || 'Negative'}. ${listofros?.ENT?.description || ''}`,
          Cardiovascular: `${listofros?.Cardiovascular?.type || 'Negative'}. ${listofros?.Cardiovascular?.description || ''}`,
          Respiratory: `${listofros?.Respiratory?.type || 'Negative'}. ${listofros?.Respiratory?.description || ''}`,
          Gastrointestinal: `${listofros?.Gastrointestinal?.type || 'Negative'}. ${listofros?.Gastrointestinal?.description || ''}`,
          Genitourinary: `${listofros?.Genitourinary?.type || 'Negative'}. ${listofros?.Genitourinary?.description || ''}`,
          Musculoskeletal: `${listofros?.Musculoskeletal?.type || 'Negative'}. ${listofros?.Musculoskeletal?.description || ''}`,
          Skin: `${listofros?.Skin?.type || 'Negative'}. ${listofros?.Skin?.description || ''}`,
          Neurological: `${listofros?.Neurological?.type || 'Negative'}. ${listofros?.Neurological?.description || ''}`,
          Psychiatric: `${listofros?.Psychiatric?.type || 'Negative'}. ${listofros?.Psychiatric?.description || ''}`,
        };
        
        setROS(rosData);
        setchiefComplaint(data["Chief Complaint"] || "Not discussed during the consultation.");
        setphysicalExamination(data["Physical Examination"] || "Not discussed during the consultation.");
        setSubjective(data["Subjective"] || "Not discussed during the consultation.");
        setObjective(data["Objective"] || "Not discussed during the consultation.");
        
        setLoading(false);
        navigate(`/SOAPnotes/${patientId}?mode=generate`);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  
  

  const redirect = () => {
    socket = null;
    setLoading(true);
    if (socketRef.current) {
      socketRef.current.close();
    }
    if(affirmation.length>50)
    {
      localStorage.setItem('conversation',affirmation)
    }
    sendMessageToBackend();

  };

  return (
    <>
      <ToastContainer />
      {
      loading ? (
        <Loading></Loading>
      ) : (
        <div className="bg-[#F0F8FF]/50 rounded-lg  shadow-lg dark:bg-gray-800 dark:border-gray-700 overflow-hidden">

          {/* <Modal open={open} onClose={() => setOpen(false)} redirect={() => navigate("/profile")}>
            <div className="flex flex-col gap-4 ">
              <div className="flex items-center text-sm  text-red-800 rounded-lg dark:bg-gray-800 dark:text-blue-400" role="alert">
                <svg
                  className="flex-shrink-0 inline w-8 h-8 mr-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 1 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="text-lg font-bold">Info alert!</span>
                </div>
              </div>
              <p >
                <IoMdCheckmarkCircleOutline
                  className="inline-block"
                  size="24"
                  color="green"
                />{" "}
                Hi, before we begin, may I record our chat for accurate charting? We use a secure AI assistant for efficient details. This allows me to focus on you while ensuring everything's documented correctly. Any questions? A nod or 'sounds good' works!
              </p>
              <hr className="border-t-solid border-1 mt-8 pb-4 border-gray-400" />
            </div>
          </Modal> */}

          <div className="flex">
            <div className="fixed h-full top-0 left-0">
              <Sidebar />
            </div>
            <div className="m-16 h-full w-full ml-72 items-center border-2 border-[#324AB2] bg-white rounded-md shadow-xl shadow-gray-600/40 ring-1 ring-gray-100">
               
              <div className="w-full flex-col">
                <div className="w-full pl-0 pt-0">
                  <div className={`grid grid-cols-6 gap-4 ${showColor ? "bg-" : "bg-[#9EB8D9]"} h-[1.2cm] pt-2 pl-4`}>
                    <div className="flex col-start-1 col-end-6">
                      <RiMicLine size={24} color="white" />
                      {showColor && (
                        <div className="justify-center items-center flex">
                          <Pulsating visible="true">
                            <StyledDiv>
                              <RiMicLine size={24} color="white" />
                              <h1 className="pl-2"> Recording</h1>
                            </StyledDiv>
                          </Pulsating>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr className={`border-t-solid border-1 ${showColor ? "mt-2" : ""} border-[#324AB2]`} />

                  <div className="flex items-center justify-center">
                    {iscounting && <Number n={3} />}
                  </div>

                  <div className="">
                    

                    
                    <div className="h-[10cm]">
                          <textarea
                            ref={textareaRef}
                            className={`w-full h-full p-4 font-bold ${isRecording ? "" : "cursor-pointer"}`}
                            type="text"
                            value={affirmation}
                            onChange={handleInputChange}
                          />
                        </div>
                    <hr className="mt-4 border-t-solid border-1 border-[#324AB2]" />
                   {btn  && <button
                          className="text-gray-900 bg-gradient-to-r mt-5 ml-5 from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={()=>{
                            setBtn(false)
                            setIsRecording(true)
                            activateMicrophone()
                          }}
                         >
                          Start transcribing
                        </button>}
                    {btn && affirmation.length>0 && <button
                          className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={()=>{
                              setLoading(true)
                              sendMessageToBackend()
                          }}
                         >
                          Generte Notes
                        </button>}

                        {btn && localStorage.getItem("previous") && <button
                          className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={()=>{
                            console.log(localStorage.getItem("previous"))
                            setAffirmation(localStorage.getItem("previous"));
                          }}
                         >
                          Previous Transcript
                        </button>}


                   

                    
                    {!open && !showStartButton && (
                      <div className="px-8 mt-6">
                       
                        
                        <button
                          className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={redirect}
                          disabled={showStartButton}
                         >
                          Generate Notes
                        </button>

                        <button
                          className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={()=>{
                            
                              window.location.reload()
                          }}
                         >
                          Start Again
                        </button>
                        <button
                          className="text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-bold rounded-lg px-5 py-2 text-center text-base mr-2 mb-2"
                          onClick={()=>{
                            
                              navigate(`/Consultation/${patientId}`)
                          }}
                         >
                          Back
                        </button>


                       
                      </div>
                    )}
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      )}
    </>
  );

}


export default Transcribing;



