// import axios from 'axios';
// import React, { createContext, useState, useEffect } from 'react';
// import { Node_API_URL } from '../client';
// import { useNavigate } from 'react-router-dom'; 
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import MainModal from '../Components/MainModal';

// // Create the context
// export const GlobalStateContext = createContext();

// // Create a provider component
// export const GlobalStateProvider = ({ children }) => {
//   const navigate = useNavigate();
  
//   // State variables
//   const [token, setToken] = useState(localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : "");
//   const [Assessment, setAssessment] = useState("");
//   const [Plan, setPlan] = useState("");
//   const [soapNotesSummary, setSoapNotesSummary] = useState("");
//   const [Allergy, setAllergy] = useState("");
//   const [HPI, setHPI] = useState("");
//   const [PMH, setPMH] = useState("");
//   const [chiefComplaint, setChiefComplaint] = useState("");
//   const [physicalExamination, setPhysicalExamination] = useState("");
//   const [cptCodes, setCptCodes] = useState(null || []);
//   const [dxCodes, setDxCodes] = useState(null || []);
//   const [med, setMed] = useState("");
//   const [subjective, setSubjective] = useState("");
//   const [objective, setObjective] = useState("");
//   const [icdCodes, setIcdCodes] = useState(null || []);
//   const [original, setOriginal] = useState("");
//   const [user, setUser] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [meeting, setMeeting] = useState({});
//   const [totalPatientCount, setTotalPatientCount] = useState("");
//   const [todayPatientCount, setTodayPatientCount] = useState("");
//   const [darkMode, setDarkMode] = useState(false); 
//   const [isopen, setIsOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState('empty');
//   const [ROS, setROS] = useState({
//     "Constitutional": "",
//     "Eyes": "",
//     "ENT": "",
//     "Cardiovascular": "",
//     "Respiratory": "",
//     "Gastrointestinal": "",
//     "Genitourinary": "",
//     "Musculoskeletal": "",
//     "Skin": "",
//     "Neurological": "",
//     "Psychiatric": "",
//   });

//   const [Rationale, setRationale] = useState({
//     "post_concussion_evaluation": "",
//     "dti_brain_mri": "",
//     "iv_micronutrients_im_vitamins": "",
//     "neurofeedback_clarity_direct": ""
//   });

//   // Default toast options
//   const defaultOptions = {
//     position: toast.POSITION.TOP_RIGHT,
//     autoClose: 3000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   }; 

//   // showToast function to show toasts with default options

//   const showToast = (message, options = {}) => {
//     toast(message, { ...defaultOptions, ...options });
//   };

//   // Token checker function
//   const checker = async () => {
//     const token = JSON.parse(localStorage.getItem("token"));
//     try {
//       const config = {
//         headers: {
//           "Authorization": `Bearer ${token}`
//         }
//       };
//       const res = await axios.get(`${Node_API_URL}/api/get/checkUserToken`, config);
//       return res.data.response === true;
//     } catch (e) {
//       return false;
//     }
//   };

//   // Fetch patients count
//   const getPaitentsCount = async () => {
//     try {
//       const config = {
//         headers: {
//           "Authorization": `Bearer ${token}`
//         }
//       };
//       const res = await axios.get(`${Node_API_URL}/api/get/getPaitentsCount`, config);
//       if (res.data.response === true) {
//         setTotalPatientCount(res.data.totalPatientCount);
//         setTodayPatientCount(res.data.todayPatientCount);
//       }
//     } catch (e) {
//       showToast('Failed to fetch patient count', { type: 'error' });
//     }
//   };

//   // Format date functions
//   const formatCreatedAtDate = (dateString) => {
//     const date = new Date(dateString);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const day = String(date.getDate()).padStart(2, '0');
//     return `${year}-${month}-${day}`;
//   };

//   const formatDateTime = (dateString) => {
//     const date = new Date(dateString);
//     let hours = date.getHours();
//     const minutes = String(date.getMinutes()).padStart(2, '0');
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12;
//     hours = hours ? hours : 12; // the hour '0' should be '12'
//     const formattedHours = String(hours).padStart(2, '0');
//     return `${formattedHours}:${minutes} ${ampm}`;
//   };

//   // Effect to update token in state when localStorage changes
//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     if (storedToken) {
//       setToken(JSON.parse(storedToken));
//     }
//   }, []);
//   const closeModel = () => {
//     setIsOpen(false);
//     setModalMessage(''); 
//     console.log("closed");
//   }; 
//   const openModel = (msg) => {   
//     setIsOpen(true); 
//     setModalMessage(msg);
//   };
//   // Global state value
//   const value = {
//     Rationale,
//     setRationale,
//     navigate,
//     meeting,
//     setMeeting,
//     setDarkMode,
//     darkMode,
//     checker,
//     getPaitentsCount,
//     totalPatientCount,
//     todayPatientCount,
//     setOriginal,
//     original,
//     formatCreatedAtDate,
//     formatDateTime,
//     loader,
//     setToken,
//     icdCodes,
//     setIcdCodes,
//     token,
//     subjective,
//     setSubjective,
//     objective,
//     setObjective,
//     med,
//     setMed,
//     cptCodes,
//     setCptCodes,
//     dxCodes,
//     setDxCodes,
//     Assessment,
//     setAssessment,
//     Plan,
//     setPlan,
//     soapNotesSummary,
//     setSoapNotesSummary,
//     Allergy,
//     setAllergy,
//     HPI,
//     setHPI,
//     PMH,
//     setPMH,
//     ROS,
//     setROS,
//     chiefComplaint,
//     setChiefComplaint,
//     physicalExamination,
//     setPhysicalExamination,
//     setUser,
//     user,
//     showToast, 
//     setIsOpen,
//     setModalMessage, 
//   };

//   return (
//     <GlobalStateContext.Provider value={value}>
//       {children}
//       <ToastContainer />   

//      {isopen && <MainModal modalMessage={modalMessage} setIsOpen={setIsOpen}/>}
//     </GlobalStateContext.Provider>
//   );
// };


import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { Node_API_URL } from '../client';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainModal from '../Components/MainModal';
import { logout } from '../features/auth/authSlice';
import { useDispatch } from 'react-redux';
// Create the context
export const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token")?JSON.parse(localStorage.getItem("token")):"");

 //recording -> soapnotes
  const [Assessment,setAssessment]=useState("")
  const [Plan,setPlan]=useState("")
  const [soapNotesSummary,setSoapNotesSummary]=useState("")
  const [Allergy,setAllergy]=useState("")
  const [HPI,setHPI]=useState("")
  const [PMH,setPMH]=useState("")
  const [ROS,setROS]=useState({
    "Constitutional":"",
    "Eyes": "",
    "ENT": "",
    "Cardiovascular":"",
    "Respiratory": "",
    "Gastrointestinal": "",
    "Genitourinary": "",
    "Musculoskeletal":"",
    "Skin": "",
    "Neurological": "",
    "Psychiatric": "",
  })

  const [chiefComplaint,setchiefComplaint]=useState("")
  const [physicalExamination,setphysicalExamination]=useState("")
  const [cptCodes,setCptCodes]=useState(null | [] )
  const [dxCodes,setDxCodes]=useState(null | [] )
  const [med,setmed]=useState("")
  const [subjective, setSubjective] = useState("");
  const [objective, setObjective] = useState("");
  const [icdCodes, setIcdCodes] = useState(null | [] );
  const [original , setOriginal] = useState("")
  const [Rationale,setRationale]=useState({
    "post_concussion_evaluation":"",
    "dti_brain_mri":"",
    "iv_micronutrients_im_vitamins":"",
    "neurofeedback_clarity_direct":""
  })
  

  //loader
  const [loader, setLoader] = useState(false);

  const [meeting,setMeeting]=useState({})

  const [isopen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('empty');

  const defaultOptions = {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }; 

  const showToast = (message, options = {}) => {
    toast(message, { ...defaultOptions, ...options });
  };


  //navigtor

  //use valid or not global access
  const [user,setUser]=useState(false)


  const formatCreatedAtDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };
 
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);

    // Format the time
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const checker = async()=>{
    const token = JSON.parse(localStorage.getItem("token"))
    try{
      const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
      const res = await  axios.get(`${Node_API_URL}/api/get/checkUserToken`,config)
      console.log(res.data.response)
      if(res.data.response === true){
        return true
      }
      return false
    }
    catch(e){
      return false
    }
  }

  const navigate = useNavigate()

  const[totalPatientCount,setTotalPatientCount]=useState("")
  const[todayPatientCount,setTodayPatientCount]=useState("")

  const getPaitentsCount = async () =>{
    try{
      const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
      const res = await axios.get(`${Node_API_URL}/api/get/getPaitentsCount`,config);
      console.log(res)
      if(res.data.response == true)
      {
        setTotalPatientCount(res.data.totalPatientCount)
        setTodayPatientCount(res.data.todayPatientCount)
      }
    }catch(e)
    {

    }
  }

  const [darkMode, setDarkMode] = useState(false);
  const [ ClearChat , setClearChat] = useState(false); 
  const [get, set]=useState(false); 
  const [Messages , setMessages] = useState ([]);   
  // const path = useLocation()
  // const dispatch = useDispatch()

  // useEffect(()=>{
  //   const excludedPaths = ['/', '/About', '/Feedback','registrationmiami'];
  //   const isNotExcluded = !excludedPaths.includes(path.pathname);
  //   if(isNotExcluded)
  //   {
  //   checker().then((res)=>{
  //     if(res==false)
  //     {
  //       setUser(false)
  //       dispatch(logout())
  //       navigate('/')
  //     }else{
  //       alert('work')
  //       getPaitentsCount()
  //     }
  //   })
  // }
  // },[])


  const value = {Rationale,setRationale,navigate,meeting,setMeeting,setDarkMode,darkMode,checker,getPaitentsCount,totalPatientCount,todayPatientCount,setOriginal,original, formatCreatedAtDate,formatDateTime,loader,setToken , icdCodes,setIcdCodes,token,subjective,setSubjective,objective,setObjective, med, setmed ,cptCodes , setCptCodes, dxCodes, setDxCodes ,Assessment , setAssessment, Plan , setPlan , soapNotesSummary, setSoapNotesSummary, Allergy , setAllergy, HPI, setHPI, PMH, setPMH, ROS, setROS,chiefComplaint,setchiefComplaint,physicalExamination,setphysicalExamination,setUser ,user,showToast, setIsOpen,     setModalMessage,setClearChat,ClearChat ,set,get ,Messages , setMessages};

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
      {isopen && <MainModal modalMessage={modalMessage} setIsOpen={setIsOpen}/>}
    </GlobalStateContext.Provider>
  );
};
