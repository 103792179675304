/*eslint-disable*/
import React, { useContext, useEffect , useState } from 'react';
import { Card, Typography, Spinner, Tooltip } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoPatientSvg } from "../images/NoPatientsYet.svg";
import { GlobalStateContext } from '../Context/GlobalStateContext';
import axios from 'axios';
import { Node_API_URL } from '../client';
import { MdEditSquare } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMicCircleSharp } from "react-icons/io5";

const TABLE_HEAD = ["#","Name" , "Email","Date of Birth", "Phone Number","Consult", "Edit","Delete"];

const Patient = ({ fetchPatients, setPatients , patients, loading }) => {
  const {token} = useContext(GlobalStateContext) 
  const options = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  const [isOpen, setIsOpen] = useState(false);  
  const [selectedOption, setSelectedOption] = useState("A");
  const [search ,setSearch] = useState(""); 
  const [query,setQuery] = useState('')
  const [query2,setQuery2] = useState('')
  const navigate = useNavigate(); 

  // useEffect(()=>{
  //   if(!user){
  //     navigate("/")
  //   }
  // })
  const handleChangeName = (event) => {
    setName(event.target.value); 
  };   
  const handleChangeEmail = (event) => {
    setMail(event.target.value); 
  }; 

  const SearchByFilter = async (SearchType,query) => { 
    try { 
    const response = await axios.post(`${Node_API_URL}/api/post/searchPatientsByType`, 
      {
      type:SearchType,
      query:query
      }, 
      {
        headers: {
          "Authorization": `Bearer ${token}`,  
        },
      }
    ); 

    // if(response.data.response)
    // {
      setPatients(response.data.patients);
    // } 
    
  } catch (error) {
    console.error('Error while searching:', error);
  }


};  

  const SearchByAlphabet = async (alphabet) => { 
    setSelectedOption(alphabet);    
    
      try {
        const response = await axios.post(
          `${Node_API_URL}/api/post/searchPatientsByAlphabet`,
          {
            query: alphabet,  
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,  
            },
          }
        ); 

        if(response.data.response)
        {
          setPatients(response.data.patients);
        } 
      } catch (error) {
        console.error('Error while searching:', error);
      }


  };  
  const toggleOpen = () => {
    setIsOpen(prevState => !prevState); // Toggle the state
  };

  const handleRowClick = (patientId,path) => {
    if(path=='icon')
    {
      navigate(`/Consultation/${patientId}`);
    }else{
      navigate(`/PatientFich/${patientId}`);
    }
  }; 


  const deletePatientHitory = async (patientId) =>{
    try{
    const pass = prompt('Enter your password')
    const data = {
      pId:patientId,
      password:pass
    }
    const response = await axios.post(`${Node_API_URL}/api/post/deletePatientHitory`, data ,{
      headers: {
        'Authorization': `Bearer ${token}` 
      }, 
  });
  if(response.data.response == true)
  {
    fetchPatients()
    alert(response.data.msg)
  }else{
    alert(response.data.msg)
  }
}catch(e)
{
  alert('Server error')
}
  }
  const handleFilter = ()=>
    {
      SearchByFilter(selected,query);
    }
    
      const [selected, setSelected] = useState('name');
    
      const handleChange = () => {
        setSelected((prevSelected) => (prevSelected === 'name' ? 'email' : 'name'));
      };
  return (
    <>  
    <div className="flex h-[70px] items-center text-gray-300 absolute top-0 left-[2px] md:left-64 p-4  w-[65vw]  md:w-[300px] lg:w-[450px] z-50"> 
<div class="relative" id="input">
  <input
    value={query} 
    onChange={(e)=>{setQuery(e.target.value)}}
    placeholder={selected==='email' ? "By Email..." : "By Name..."}
    class="flex justify-center items-center w-full text-sm h-[35px] px-4 text-slate-900 bg-gray-700 rounded-[8px] appearance-none focus:outline-none hover:outline-none peer invalid:outline-none overflow-ellipsis overflow-hidden text-nowrap pr-[48px]"
    id="floating_outlined"
    type="text"
  />
  <div class="absolute top-[6px] sm:top-[6px] right-3"> 

  <svg
  xmlns="http://www.w3.org/2000/svg"
  className='text-white cursor-pointer' 
  onClick={handleFilter}
  viewBox="0 0 24 24"
  height="20" 
  width="20"
  fill="white"  // Set fill color to white
>

      <path
        d="M10.979 16.8991C11.0591 17.4633 10.6657 17.9926 10.0959 17.9994C8.52021 18.0183 6.96549 17.5712 5.63246 16.7026C4.00976 15.6452 2.82575 14.035 2.30018 12.1709C1.77461 10.3068 1.94315 8.31525 2.77453 6.56596C3.60592 4.81667 5.04368 3.42838 6.82101 2.65875C8.59833 1.88911 10.5945 1.79039 12.4391 2.3809C14.2837 2.97141 15.8514 4.21105 16.8514 5.86977C17.8513 7.52849 18.2155 9.49365 17.8764 11.4005C17.5979 12.967 16.8603 14.4068 15.7684 15.543C15.3736 15.9539 14.7184 15.8787 14.3617 15.4343C14.0051 14.9899 14.0846 14.3455 14.4606 13.9173C15.1719 13.1073 15.6538 12.1134 15.8448 11.0393C16.0964 9.62426 15.8261 8.166 15.0841 6.93513C14.3421 5.70426 13.1788 4.78438 11.81 4.34618C10.4412 3.90799 8.95988 3.98125 7.641 4.55236C6.32213 5.12348 5.25522 6.15367 4.63828 7.45174C4.02135 8.74982 3.89628 10.2276 4.28629 11.6109C4.67629 12.9942 5.55489 14.1891 6.75903 14.9737C7.67308 15.5693 8.72759 15.8979 9.80504 15.9333C10.3746 15.952 10.8989 16.3349 10.979 16.8991Z"
      ></path>
      <rect
        transform="rotate(-49.6812 12.2469 14.8859)"
        rx="1"
        height="10.1881"
        width="2"
        y="14.8859"
        x="12.2469"
      ></rect>
    </svg>
  </div>
</div>
<div className="flex gap-2">

      {/* Second Checkbox */}
        <span className="ml-3 text-gray-200">Email:</span>
        <input
        type="checkbox"
        checked={selected === 'email'} // Check if 'email' is selected
        onChange={handleChange} // Toggle on change
        className="form-checkbox cursor-pointer h-4 w-4 mt-1 ml-0 mr-1 md:h-5 md:w-5 md:ml-2 text-blue-600 rounded focus:ring focus:ring-blue-300 focus:outline-none"
      />
     
    </div>
</div> 


    {/* <div className='flex justify-between flex-col p-4  md:p-8 mb-4 dark:bg-slate-800 items-center lg:flex-row'> 
    <div class="flex w-[50vw]   md:w-[20vw]  items-center justify-between gap-2 bg-[#2f3640] rounded-full relative">
    <input  
        class="border-none bg-transparent outline-none text-white text-sm px-2 py-3 pl-4 md:px-6 md:py-6  md:pl-7" 
        type="text" 
        value={query1} 
        onChange={(e)=>setQuery1(e.target.value)} 
        placeholder="Search by name.." 
    />
    <button 
        class="absolute right-2 w-10 h-8 md:w-10 md:h-10 rounded-full bg-gradient-to-r from-[#2AF598] to-[#009EFD] border-0 hover:bg-[#1A1A1A] hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:-translate-y-1 transition-all duration-300 ease-[cubic-bezier(0.23,1,0.32,1)] active:shadow-none active:translate-y-0"
    onClick={() => SearchByFilter('name',query1)}
    > 
    
        <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_2_17)">
                <g filter="url(#filter0_d_2_17)">
                    <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"></path>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape"></feBlend>
                </filter>
                <clipPath id="clip0_2_17">
                    <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)"></rect>
                </clipPath>
            </defs>
        </svg>
    </button>
</div> 
<div class="flex w-[50vw]  md:w-[20vw]  mb-3  items-center justify-between gap-2 bg-[#2f3640] rounded-full relative">
    <input 
        class="border-none bg-transparent outline-none text-white text-sm px-2 py-3 pl-4 md:px-6 md:py-6  md:pl-7" 
        type="text" 
        value={query2} 
        onChange={(e)=>setQuery2(e.target.value)} 
        placeholder="Search by Email.." 
    />
    <button 
        class="absolute right-2 w-10 h-8 md:w-10 md:h-10 rounded-full bg-gradient-to-r from-[#2AF598] to-[#009EFD] border-0 hover:bg-[#1A1A1A] hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:-translate-y-1 transition-all duration-300 ease-[cubic-bezier(0.23,1,0.32,1)] active:shadow-none active:translate-y-0"
    onClick={() => SearchByFilter('email',query2)}
    > 
    
        <svg xmlns="http://www.w3.org/2000/svg" className='mx-auto' width="29" height="29" viewBox="0 0 29 29" fill="none">
            <g clip-path="url(#clip0_2_17)">
                <g filter="url(#filter0_d_2_17)">
                    <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"></path>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feComposite in2="hardAlpha" operator="out"></feComposite>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape"></feBlend>
                </filter>
                <clipPath id="clip0_2_17">
                    <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)"></rect>
                </clipPath>
            </defs>
        </svg>
    </button>
</div>

       </div>  */}
       
       <Card className="h-[100vh] w-full border-2 bg-[#FDFEE2] dark:bg-slate-800 border-blue-600 rounded-lg shadow-2xl p-6 dark:text-white">
    {loading ? (
      <div className="flex justify-center  items-center h-full">
        <Spinner className="h-12 w-12 text-blue-600" />
      </div>
    ) : (
      <>
  {patients.length > 0 ? (
    <div className="overflow-x-auto"> {/* Add overflow-x-auto here */}
      <table className="w-full table-auto text-left">
        <thead className="bg-blue-600 text-white">
          <tr>
            {TABLE_HEAD.map((head) => (
              <th key={head} className="p-4">
                <Typography variant="small" className="font-semibold uppercase">
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {patients.map((patient, index) => {
            const isLast = index === patients.length - 1;
            const classes = isLast ? "p-1" : "p-1 border-b border-blue-100";

            return (
              <tr
               
                key={index}
                className="hover:bg-blue-50 transition-colors cursor-pointer dark:hover:text-blue-500"
              >
                <td  onClick={() => handleRowClick(patient._id,'row')}  className={classes}>
                  {index+1}
                </td>
                <td  onClick={() => handleRowClick(patient._id,'row')}  className={classes}>
                  {patient.fullName}
                </td>
                <td  onClick={() => handleRowClick(patient._id,'row')}  className={classes}>
                  {patient.email}
                </td>
                <td  onClick={() => handleRowClick(patient._id,'row')}  className={classes}>
                  {patient?.dateOfBirth ? patient?.dateOfBirth : 'N/A'}
                </td>
                <td  onClick={() => handleRowClick(patient._id,'row')}  className={classes}>
                  {patient?.phoneNumber ? patient?.phoneNumber : 'N/A'}
                </td>
                
                <td className={`${classes}`}>
                  <Tooltip content="Edit Patient" placement="top">
                    <IoMicCircleSharp className='mx-auto' onClick={() => navigate(`/Consultation/${patient._id}`)}  size={25}/>
                  </Tooltip>
                </td>
                <td className={`${classes} mx-auto`}>
                  <Tooltip content="Edit Patient" placement="top">
                    <MdEditSquare onClick={() => handleRowClick(patient._id,'row')}  size={25}/>
                  </Tooltip>
                </td>
                <td onClick={() => deletePatientHitory(patient._id)} className={classes}>
                  <Tooltip content="Edit Patient" placement="top">
                    <RiDeleteBin6Fill size={25 }/>
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center p-8 ">
      <NoPatientSvg className="w-64 h-64 " />
      <Typography variant="h5" className="font-bold dark:text-white text-2xl text-center">
        No Patients Yet
      </Typography>
      <Typography variant="body2" className="text-center dark:text-white mt-2">
        Start by adding new patients to see them listed here.
      </Typography>
    </div>
  )}
        </>
      )}
</Card>
    </>

  );
};

export default Patient;
