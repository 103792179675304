/*eslint-disable*/
import React from 'react'
import RegisterPatient from '../../Components/Share/RegisterPatient'

const ShareForm = () => {
  return (
    <RegisterPatient/>
  )
}

export default ShareForm