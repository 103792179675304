import React, { useContext, useState } from 'react';
import  '../styles/registraion.css'
import { GlobalStateContext } from '../Context/GlobalStateContext';
import logo from "../images/logo.png"
import { ToastContainer, toast } from "react-toastify";
import { MAIL_SERVER } from '../client';

function Registration() {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const { navigate } = useContext(GlobalStateContext)

  // State to store form input values and loading status
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    question:"",
    location:"Miami"
  });

  const [loading, setLoading] = useState(false); // Add loading state

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true); // Start loading

    try {
      const response = await fetch(`${MAIL_SERVER}/api/post/email/sendMailToAims`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        toast.error("Network response was not ok");
      }

      // Handle successful submission
      toast.success("Message sent successfully.");
      // Clear form fields if needed
      setFormData({
        name: "",
        email: "",
        phone: "",
        question:"",
        location:"Miami"
      });

    } catch (error) {
      // Handle errors
      toast.error("There was a problem with sending request");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
    <ToastContainer/>
    <div className='container-reg'>
      <div className="forms-container-reg">
        <div className="signin-signup">
          <form onSubmit={handleSubmit} className={`sign-in-form ${isSignUpMode ? 'opacity-0 z-1' : 'z-2'}`}>
            <h2 className="title">Registration</h2>
            <div className="input-field">
              <i className="fas fa-user"></i>
              <input value={formData.name} onChange={handleChange} name='name'  type="text" placeholder="Full Name" className="input" required />
            </div>
            <div className="input-field">
            <i class="fas fa-phone"></i>
              <input value={formData.phone} onChange={handleChange} name='phone'  type="number" placeholder="Phone Number" className="input" required />
            </div>
            <div className="input-field">
              <i className="fas fa-at"></i>
              <input value={formData.email} onChange={handleChange} name='email' type="email" placeholder="Email Address" className="input"  required/>
            </div>
            <div className="input-field">
            <i class="fas fa-question"></i>
              <input value={formData.question} onChange={handleChange} name='question' type="text" placeholder="Any questions regarding AIMS?" className="input"  required/>
            </div>
             {/* Dropdown menu for locations */}
            <div className="input-field">
              {/* <label htmlFor="location">Location:</label> */}
              <i class="fas fa-map-pin"></i>
              <select  onChange={handleChange} value={formData.location} className="bg-transparent outline-none border-none input" id="location" name="location" required>
                <option value="Miami">Miami</option>
                <option value="New York">New York</option>
                <option value="California">California</option>
                <option value="Washington">Washington</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Georgia">Georgia</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <input disabled={loading}  type='submit' value={loading?"loading...":"submit"} className="btn solid" />
            <p className="social-text">Visit Us</p>
            <div className="social-media">
              <a href="https://www.youtube.com/@AIscribers" target='_blank'  className="social-icon">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.instagram.com/aiscribers/" target='_blank'  className="social-icon">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="mailto:jasmelacosta@gmail.com" target='_blank' className="social-icon">
                <i className="fas fa-envelope"></i>
              </a>
              <a href="https://www.linkedin.com/company/ai-medical-scribers/" target='_blank' className="social-icon">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </form>

         
        
        </div>
      </div>

      <div className="panels-container-reg">
        <div className="panel left-panel">
          <div className="content">
            <h3>Artificial Intelligence Solution</h3>
            <br />
            <button
              className="btn transparent"
              onClick={() => {setIsSignUpMode(true);setTimeout(()=>navigate('/'),1000) }}
            >
              Back
            </button>
            <img className="image  w-32 h-76 ml-16" src={logo} />
          </div>
        </div>
        
      </div>
    </div>

    <style>
        {
            `
            @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

.container-reg {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container-reg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #4d84e2;
}
.panels-container-reg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container-reg:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container-reg.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container-reg.sign-up-mode .left-panel .image,
.container-reg.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container-reg.sign-up-mode .signin-signup {
  left: 25%;
}

.container-reg.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container-reg.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container-reg.sign-up-mode .right-panel .image,
.container-reg.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container-reg.sign-up-mode .left-panel {
  pointer-events: none;
}

.container-reg.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container-reg {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container-reg.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container-reg {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container-reg:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container-reg.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container-reg.sign-up-mode .left-panel .image,
  .container-reg.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container-reg.sign-up-mode .right-panel .image,
  .container-reg.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container-reg.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }
  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container-reg {
    padding: 1.5rem;
  }

  .container-reg:before {
    bottom: 80%;
    left: 50%;
  }

  .container-reg.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
            `
        }
    </style>
    </>
  );
}

export default Registration;
