import React from 'react';
import { FaRegListAlt, FaRegCalendarAlt, FaRegStickyNote, FaRegFileAlt, FaRegClock } from 'react-icons/fa';

// Fake data
const meetingData = {
  objectives: "Discuss project updates, plan next steps, and address any issues.",
  highlights: "Project on track, team successfully met milestones.",
  agendaItems: [
    {
      speaker: "John Doe",
      keyPoints: "Reviewed project progress and challenges.",
      decisions: "Decided to move forward with the new development strategy.",
      actionItems: [
        {
          name: "Update project plan",
          person: "Alice Smith",
          dueDate: "2024-07-30",
          dependencies: "Approval from the client",
          resourcesNeeded: "Project management software",
          successCriteria: "Updated plan reviewed and approved",
          progressTracking: "Weekly progress reports"
        }
      ]
    },
    {
      speaker: "Jane Doe",
      keyPoints: "Addressed technical issues and proposed solutions.",
      decisions: "Approved new tech stack.",
      actionItems: [
        {
          name: "Implement new tech stack",
          person: "Bob Johnson",
          dueDate: "2024-08-05",
          dependencies: "Vendor support",
          resourcesNeeded: "Tech stack documentation",
          successCriteria: "Tech stack implemented and tested",
          progressTracking: "Bi-weekly technical reviews"
        }
      ]
    }
  ],
  followUpItems: [
    "Schedule follow-up meeting to review progress on action items.",
    "Prepare a detailed report on the new tech stack implementation."
  ],
  decisionLog: [
    "Approved new development strategy.",
    "Accepted proposal for tech stack change."
  ],
  issuesRisks: [
    {
      issue: "Potential delay in tech stack implementation.",
      mitigationStrategy: "Work with vendor to expedite support."
    }
  ],
  nextSteps: [
    "Finalize updated project plan.",
    "Begin implementation of new tech stack."
  ],
  attachments: [
    "Project Plan Document",
    "Tech Stack Proposal"
  ]
};

// // MeetingTemplate component
const MeetingTemplate = () => {
  return (
    <div className="p-8 max-w-[210mm] mx-auto border border-gray-300 bg-gray-50 shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-6 flex items-center text-teal-700">
        <FaRegListAlt className="mr-3 text-teal-600" /> Meeting Template
      </h1>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegCalendarAlt className="mr-2 text-teal-500" /> 1. Meeting Objectives
        </h2>
        <p>{meetingData.objectives}</p>
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegStickyNote className="mr-2 text-teal-500" /> 2. Highlights
        </h2>
        <p>{meetingData.highlights}</p>
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegFileAlt className="mr-2 text-teal-500" /> 3. Detailed Notes by Agenda Items
        </h2>
        {meetingData.agendaItems.map((item, index) => (
          <div key={index} className="mb-4 border border-teal-300 bg-gray-50 p-4 rounded-lg shadow-sm">
            <h3 className="font-semibold flex items-center mb-2 text-teal-700">
              <FaRegStickyNote className="mr-2 text-teal-600" /> Speaker: {item.speaker}
            </h3>
            <p><strong>Key Points Discussed:</strong> {item.keyPoints}</p>
            <p><strong>Decisions Made:</strong> {item.decisions}</p>
            <h4 className="font-semibold flex items-center mt-4 mb-2 text-teal-700">
              <FaRegClock className="mr-2 text-teal-600" /> Action Items:
            </h4>
            <ul className="list-disc pl-5">
              {item.actionItems.map((action, idx) => (
                <li key={idx} className="mb-2">
                  <strong>{action.name}</strong><br />
                  Person: {action.person}<br />
                  Due Date: {action.dueDate}<br />
                  Dependencies: {action.dependencies}<br />
                  Resources Needed: {action.resourcesNeeded}<br />
                  Success Criteria: {action.successCriteria}<br />
                  Progress Tracking: {action.progressTracking}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegStickyNote className="mr-2 text-teal-500" /> 4. Follow-Up Items
        </h2>
        <ul className="list-disc pl-5">
          {meetingData.followUpItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegFileAlt className="mr-2 text-teal-500" /> 5. Decision Log
        </h2>
        <ul className="list-disc pl-5">
          {meetingData.decisionLog.map((decision, index) => (
            <li key={index}>{decision}</li>
          ))}
        </ul>
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegStickyNote className="mr-2 text-teal-500" /> 6. Issues/Risks Identified and Mitigation Strategies
        </h2>
        {meetingData.issuesRisks.map((item, index) => (
          <div key={index} className="mb-2">
            <p><strong>Issue:</strong> {item.issue}</p>
            <p><strong>Mitigation Strategy:</strong> {item.mitigationStrategy}</p>
          </div>
        ))}
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegClock className="mr-2 text-teal-500" /> 7. Next Steps
        </h2>
        <ul className="list-disc pl-5">
          {meetingData.nextSteps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      </section>
      
      <section className="mb-6 border border-teal-200 bg-white p-4 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold flex items-center mb-2 text-teal-600">
          <FaRegFileAlt className="mr-2 text-teal-500" /> 8. Attachments/Resources
        </h2>
        <ul className="list-disc pl-5">
          {meetingData.attachments.map((attachment, index) => (
            <li key={index}>{attachment}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default MeetingTemplate;



